// import { Carousel } from "react-bootstrap";
// import Link from "next/link";
//import OptionDropdown from "components/category/about/optionDropdown/optionDropdown";
import { useSelector } from "react-redux";
// import { postLike } from "redux/actions/postAction";
import React, { useEffect, useState } from "react";
// import { useAmp } from "next/amp";
import CustomPopup from "components/shared/modal/customPopup";
// import { useSession } from "next-auth/react";
// import { getLanguage } from "localization/i8intl";
import { pushGoogleTabEvent, createHomeArticlePayload } from "api/googletab";
import { useRouter } from "next/router";
// import {
//   FacebookIcon,
//   FacebookShareButton,
//   WhatsappIcon,
//   WhatsappShareButton,
//   TwitterShareButton,
//   TwitterIcon,
//   LinkedinIcon,
//   LinkedinShareButton,
//   EmailIcon,
//   EmailShareButton,
// } from "next-share";
// // import uiHelper from "utils/uiHelper";
// // import { saveBookmark } from "redux/actions/bookmarksActions";
// import { Modal } from "react-bootstrap";
// import { timeSince } from "constants/cardsConstant";
// import ClevertapReact from "utils/clevertapHelper";
// import { MyLink } from "api/cleverTap";

const NewsImageBanner = ({ postData }) => {
  // console.log("In newsImageBanner", postData);
  //const sessionData = JSON.parse(localStorage.getItem("lallantopSession2"));
  const router = useRouter();
  const { query } = router;
  // const { data: session } = useSession();
  // const { userLocalDetail } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loginPrompt, setLoginPrompt] = useState(false);
  // const [videoDuration, setVideoDuration] = useState(0);
  const [postStateData, setPostStateData] = useState(postData);
  // const [openShareModal, setOpenShareModal] = useState(false);
  // const [shareData, setShareData] = useState({});
  const { postDetails } = useSelector(({ PostReducer }) => PostReducer);
  const isHomePath = router.asPath === "/";
  // const dispatch = useDispatch();
  // const countFormat = (count) => {
  //   return count > 1000 ? (count / 1000).toFixed(1).concat("k") : count;
  // };
  // const handleLike = (_id, postDetails) => {
  //   if (userLocalDetail?.id || session?.user?.email) {
  //     const { seriesId } = postStateData;
  //     dispatch(postLike({ _id, seriesId }, null, postDetails));
  //   } else {
  //     setLoginPrompt(true);
  //   }
  // };
  // const [language, setLanguage] = useState("");
  // useEffect(() => {
  //   setLanguage(getLanguage());
  // }, []);

  const pageToOpen = `/${
    postStateData?.categorySlug?.toLowerCase() || query?.categoryName
  }/post/${postStateData?.postSlug || ""}`;

  // const shareUrl = `${pageToOpen}?postId=${postStateData?._id}`;
  useEffect(() => {
    if (postDetails?._id === postData?._id) {
      setPostStateData(postDetails);
    }
  }, [postDetails, postData?._id]);

  console.log("live blgo data ", postData);
  // useEffect(() => {
  //   var au = document?.createElement("audio");
  //   au.src = postStateData?.assetFileLink;
  //   function handler() {
  //     var duration = au?.duration;
  //     setVideoDuration(new Date(duration * 1000).toISOString().substr(14, 5));
  //   }
  //   au.addEventListener("loadedmetadata", handler);
  //   return () => {
  //     au.removeEventListener("loadedmetadata", handler);
  //   };
  // }, [postStateData]);

  // const handleShare = () => {
  //   const share = {
  //     title: postData?.title,
  //     text: postData?.title,
  //   };
  //   //if (shareUrl) share.url = shareUrl;

  //   share.url = postData?.permalink;

  //   //console.log("share: ", share);
  //   setShareData(share);
  //   if (!navigator || !navigator.share) {
  //     setOpenShareModal(true);
  //     console.log("Platform not supported!");
  //   } else {
  //     uiHelper.share(share);
  //   }
  // };

  // const handleBookmark = (_id, type) => {
  //   if (userLocalDetail?.id || session?.user?.email) {
  //     const postObj = {
  //       table: "post",
  //       postId: _id,
  //       type: type,
  //     };
  //     dispatch(saveBookmark(postObj));
  //     //setShowDropdown((e) => !e);
  //   } else {
  //     if (setLoginPrompt) setLoginPrompt(true);
  //   }
  // };

  return (
    <div
      className="newsImageBanner"
      style={{ width: "inherit", height: "auto", marginBottom: "1rem" }}
    >
      <a
        // href={{
        //   pathname:
        //     postData.eventStatus === undefined
        //       ? pageToOpen
        //       : `/business/post/${postData?.sefUrl}`,
        //   // query: {
        //   //   categoryName: postStateData?.name,
        //   //   postSlug: postStateData?.postSlug,
        //   //    categorySlug: postStateData?.categorySlug,
        //   // },
        // }}
        href = {`${
          postData.eventStatus === undefined
            ? pageToOpen
            : `/business/post/${postData?.sefUrl}`}`}
        passHref
      >
        <a
          className="headline-thumb"
          onClick={() => {
            //console.log(isHomePath, "home path");
            if (isHomePath) {
              pushGoogleTabEvent(
                "lt_cards",
                createHomeArticlePayload(postStateData, { Section: "Home" })
              );
              // ClevertapReact.event(
              //   "homepage_card",
              //   createHomeCardPayload("homepage_card", postStateData)
              // );
            } else if (router.asPath === "/video") {
              // console.log("bdeio");
              pushGoogleTabEvent(
                "lt_cards",
                createHomeArticlePayload(postStateData, {
                  Section: "Video",
                })
              );
            }
          }}
        >
          <img
            src={
              postData.thumbnail !== undefined
                ? postData?.thumbnail + "?width=360&quality=50"
                : postStateData?.headingImageUrl + "?width=360&quality=50"
            }
            alt={postStateData?.imgAltText || "News pic"}
            width="100%"
            height={"inherit"}
            className="newsImageBannerImage"
            // eslint-disable-next-line react/no-unknown-property
            fetchPriority="high"
            //loading="lazy"
          />
          {postData.type === "liveblog" &&
            postData?.liveBlogData?.eventStatus === "live" && (
              <div style={{ position: "absolute", top: "-15px", right: "0" }}>
                <img
                  src="assets/images/giphy.gif"
                  width="60px"
                  height="80px"
                  alt="live"
                ></img>
              </div>
            )}
          <div className="image-content image-content-fallback">
            <div className="news-type-icon" style={{ color: "white" }}>
              {postData.eventStatus === undefined && (
                <i
                  className={`icon-${
                    postStateData?.type === "text" ||
                    postData.type === "liveblog"
                      ? "cat-read"
                      : postStateData?.type === "audio"
                      ? "mic"
                      : "play-circle"
                  }`}
                ></i>
              )}
              {/* {
                postStateData.eventStatus === "live" &&
                <>
                <img src="assets/images/live_gif.gif" width="70px" height="25px"></img>
                
                </>
              } */}
            </div>
            <div className="heading">
              <a
                href={pageToOpen}
                style={{
                  fontFamily:
                    "Mukta,system-ui, -apple-system, BlinkMacSystemFont",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  color: "white",
                  maxHeight: "7.5rem",
                }}
                onCustomClick={() => {
                  if (isHomePath) {
                    // ClevertapReact.event(
                    //   "homepage_card",
                    //   createHomeCardPayload("homepage_card", postStateData)
                    // );
                  }
                }}
              >
                <h2
                  style={{
                    fontWeight: "500",
                    fontSize: "20px",
                    marginBottom: "0rem",
                  }}
                >
                  {postStateData?.title?.padEnd(88)}
                </h2>
              </a>
            </div>
          </div>
        </a>
      </a>

      <CustomPopup
        showModal={loginPrompt}
        onClose={() => setLoginPrompt(false)}
      />
      {/* <Modal
        show={openShareModal}
        fullscreen={false}
        centered
        size="sm"
        onHide={() => setOpenShareModal(false)}
      >
        <div className="row p-2">
          <div className="col-6">Social Share</div>
          <div className="col-6 text-end">
            <div role={"button"} onClick={() => setOpenShareModal(false)}>
              <span className="icon-close"></span>
            </div>
          </div>
        </div>

        <Modal.Body>
          <div align="center">
            <FacebookShareButton quote={shareData.text} url={shareData.url}>
              <FacebookIcon className="me-1" size={32} round />
            </FacebookShareButton>

            <TwitterShareButton title={shareData.text} url={shareData.url}>
              <TwitterIcon className="me-1" size={32} round />
            </TwitterShareButton>

            <EmailShareButton
              url={shareData.url}
              subject={shareData.text}
              body={shareData.url}
            >
              <EmailIcon className="me-1" size={32} round />
            </EmailShareButton>

            <LinkedinShareButton title={shareData.text} url={shareData.url}>
              <LinkedinIcon className="me-1" size={32} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={shareData.url} title={shareData.text}>
              <WhatsappIcon className="me-1" size={32} round />
            </WhatsappShareButton>
            <hr />
            <span style={{ fontSize: "13px" }} className="text-dark">
              <span id="cpy">Copy</span> to Clipboard{" "}
              <i className="las la-clipboard la-lg"></i>
            </span>
            <input
              style={{ fontSize: "13px" }}
              onClick={() => {
                let et = document.getElementById("copy");
                et.select();
                document.execCommand("copy");
                document.getElementById("cpy").textContent = "Copied";
              }}
              id="copy"
              value={shareData.url}
              type="text"
              readOnly
              className="form-control"
            />
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};
export default NewsImageBanner;
